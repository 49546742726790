@if (!viewNewStation) {
  <section class="grid grid-cols-12 py-5 border-b border-secondary-400">
    <div class="col-span-12">
      <h6 class="text-center">{{ termsGeneric.Station.Single }} Information</h6>
    </div>
    <div class="col-span-12">
      <p class="leading-none tracking-widest uppercase font-semibold text-xs">
        {{ termsGeneric.Station.Single }} Name
      </p>
    </div>
    <div class="col-span-12">
      <div class="flex justify-between items-center w-full">
        <span>{{ stationName }}</span>
        <button
          type="button"
          data-testid="station-info-drawer"
          mat-icon-button
          (click)="toggleDrawer('stationInfo')"
          [disabled]="isDrawerOpen"
        >
          <i
            class="fas fa-info-circle text-2xl"
            [ngClass]="isDrawerOpen ? 'text-primary-100' : 'text-primary-500'"
          ></i>
        </button>
      </div>
    </div>
  </section>
} @else {
  <ng-container *ngTemplateOutlet="newStationHeader" />
}

<ng-template #newStationHeader>
  <section class="text-secondary-100">
    <button
      mat-icon-button
      type="button"
      id="go-back"
      data-testid="go-back"
      (click)="goBack()"
    >
      <i class="fa-light fa-arrow-left-long text-xl"></i>
    </button>
    <div class="sm:flex sm:justify-between sm:items-center sm:gap-3">
      <div
        class="flex items-center justify-between sm:justify-start gap-3 w-full sm:w-auto"
      >
        <div class="truncate">
          <h4 class="truncate font-semibold">{{ stationName }}</h4>
          @if (
            {
              stationGroupName: stationGroupName$ | async,
              error: stationGroupNameError$ | async
            };
            as nameGroup
          ) {
            @if (
              !nameGroup.error &&
              (nameGroup.stationGroupName || nameGroup.stationGroupName === '')
            ) {
              <p class="text-xs truncate">
                {{ nameGroup.stationGroupName }}
              </p>
            } @else {
              <ng-container *ngTemplateOutlet="loading" />
            }
            @if (nameGroup.error; as error) {
              <div class="flex justify-center pt-4">
                <p
                  class="text-error-A400 text-sm"
                  id="error-get-group-name"
                  data-testid="error-get-group-name"
                >
                  Error returning group name.
                </p>
              </div>
            }
          }
        </div>
      </div>
      @if (isArchitect) {
        <div class="hidden sm:flex items-end gap-3 mr-4">
          @if (
            {
              stationAdmins: stationAdmins$ | async,
              error: stationAdminsError$ | async
            };
            as admins
          ) {
            @if (admins.error; as error) {
              <div class="text-white">
                <p
                  class="text-error-A400 text-sm"
                  id="error-get-admin"
                  data-testid="error-get-admin"
                >
                  Error returning the admins
                </p>
              </div>
            }
            @if (!admins.error && admins.stationAdmins) {
              @if (admins.stationAdmins.length) {
                <p class="text-xs">{{ termsGeneric.Station.Single }} Admins</p>
                <app-roster
                  [stationId]="stationRithmId"
                  [isWorker]="false"
                  [stationMembers]="admins.stationAdmins"
                  [fromDrawer]="true"
                />
              } @else {
                <p class="text-xs mb-2">No admin to show</p>
              }
            } @else {
              <ng-container *ngTemplateOutlet="loading" />
            }
          }

          <button
            type="button"
            data-testid="info-drawer-button-station"
            id="info-drawer-button-station"
            mat-icon-button
            (click)="toggleDrawer('stationInfo')"
            [disabled]="isDrawerOpen"
            [matTooltip]="
              'Open ' +
              (termsGeneric.Station.Single | lowercase) +
              ' info drawer'
            "
          >
            <i
              class="fa-light fa-info-circle text-xl"
              [ngClass]="{ 'text-secondary-200': isDrawerOpen }"
            ></i>
          </button>
        </div>
      }
    </div>
  </section>
</ng-template>

<ng-template #loading>
  <div>
    <app-loading-indicator
      [color]="'text-white'"
      [inlineText]="'Loading...'"
      id="loading-account-setting"
      data-testid="loading-account-setting"
    />
  </div>
</ng-template>
