@if (!isWidget && isArchitect) {
  <section
    class="flex justify-between items-center border-b border-secondary-200 py-2"
  >
    <h4
      class="truncate font-semibold"
      [ngClass]="{ 'animate-pulse text-sm': !containerLabel }"
    >
      {{ containerLabel || 'Loading...' }}
    </h4>
    <button
      data-testid="info-drawer-btn-document"
      id="info-drawer-btn-document"
      mat-icon-button
      (click)="toggleDrawer('containerInfo')"
      [disabled]="isDrawerOpen"
      [matTooltip]="
        'Open ' +
        (containerLabel || termsGeneric.Container.Single | lowercase) +
        ' info drawer'
      "
      type="button"
    >
      <i
        class="fa-light fa-info-circle text-2xl"
        [ngClass]="isDrawerOpen ? 'text-secondary-100' : 'text-secondary-500'"
      ></i>
    </button>
  </section>
}

@if (isWidget) {
  <section id="section-current-worker">
    <div class="py-2">
      @if (containerName.length) {
        <div class="container-name">
          <ng-container
            *ngTemplateOutlet="
              isUserAdminOrOwner || (isDocumentNameEditable && isUserInWorkers)
                ? inputEditDocumentName
                : nonEditableDocNameWidget
            "
          />
          <ng-template #nonEditableDocNameWidget>
            <p class="font-semibold">
              {{ containerName }}
            </p>
          </ng-template>
        </div>
      } @else {
        <ng-container *ngTemplateOutlet="loadingDocumentName" />
      }
    </div>
  </section>
}

<ng-template #inputEditDocumentName>
  <div class="w-full flex flex-col">
    <form
      [formGroup]="documentNameForm"
      class="flex items-center document-header-form"
    >
      <mat-form-field appearance="outline" class="w-full">
        <mat-label
          >{{
            containerLabel || termsGeneric.Container.Single
          }}
          Name:</mat-label
        >
        <input
          id="name"
          data-testid="name"
          type="text"
          matInput
          matNativeControl
          autocomplete="off"
          placeholder="Untitled {{
            containerLabel || termsGeneric.Container.Single
          }}"
          formControlName="name"
          (keyup)="updateContainerNameBS()"
        />
      </mat-form-field>
      @if (!isWidget) {
        <button
          type="button"
          data-testid="container-info-drawer"
          mat-icon-button
          class="icon icon-top pl-2"
          (click)="toggleDrawer('containerInfo')"
          id="info-drawer-button-document"
          [disabled]="isDrawerOpen"
        >
          <i
            class="fa-solid fa-info-circle text-2xl"
            [ngClass]="isDrawerOpen ? 'text-primary-100' : 'text-primary-500'"
          ></i>
        </button>
      }
    </form>
    @if (appendedDocumentName) {
      <p class="text-xs text-secondary-500">
        Appended Name: {{ appendedDocumentName }}
      </p>
    }
  </div>
</ng-template>

<ng-template #loadingDocumentName>
  <app-loading-indicator
    [inlineText]="
      'Loading ' + (termsGeneric.Container.Single | lowercase) + ' name'
    "
    id="loading-container-name"
  />
</ng-template>
