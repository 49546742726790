<mat-drawer-container
  [hasBackdrop]="drawerHasBackdrop"
  class="h-full info-drawer-document"
>
  <mat-drawer #detailDrawer mode="over" position="end">
    <!-- Show detail drawer -->
    @if (drawerContext === 'comments') {
      @defer {
        <app-detail-drawer
          (checkClickOutsideComment)="checkClickOutsideComment($event)"
        />
      }
    }

    <!-- Show detail drawer -->
    @if (
      drawerContext === 'stationInfo' ||
      drawerContext === 'containerInfo' ||
      drawerContext === 'history'
    ) {
      @defer {
        <app-info-drawer
          [isOutsideView]="true"
          [isUsedFromMap]="isUsedFromMap"
        />
      }
    }
  </mat-drawer>

  <mat-drawer-content class="scrollbar container-page bg-ribbon">
    <!-- Show content of container -->
    @if (containerInformation) {
      <div
        class="h-full drawer-content relative overflow-auto flex flex-col w-[90%] m-auto justify-center"
      >
        <section class="w-full">
          <div class="h-[100px]">
            <!-- Show station info drawer -->
            @if (!isWidget) {
              <app-station-info-header
                [stationInformation]="containerInformation"
                [stationEditMode]="false"
                [viewNewStation]="true"
                [isArchitect]="isArchitect"
              />
            }
          </div>
        </section>
        <!-- Center column -->
        <div
          class="bg-white rounded-t-lg overflow-auto scrollbar w-full"
          [ngClass]="{
            'pb-12': !containerLoading
          }"
        >
          <!-- Content to show loading -->
          @if (containerLoading) {
            <div
              class="h-full flex flex-col justify-center items-center"
              [ngClass]="{ loading: !isWidget }"
            >
              <app-loading-indicator />
            </div>
          }

          <!-- Form of container -->
          @if (!containerLoading) {
            <form
              id="container-info-template"
              autocomplete="off"
              [formGroup]="containerForm"
              class="container-base-form w-full"
              (ngSubmit)="saveContainerChanges()"
            >
              <!-- Show container info header -->
              @if (!containerLoading) {
                <div class="w-full px-4">
                  <app-container-info-header
                    [isWidget]="isWidget"
                    [containerInformation]="containerInformation"
                    [containerRithmId]="containerId"
                    [stationRithmId]="stationId"
                    [isArchitect]="isArchitect"
                  />
                </div>
              }

              <!-- Show container template when is not widget -->
              @if (!isWidget) {
                <div id="container-grid">
                  <app-container-template
                    [relationshipWidgetFlag]="relationshipWidgetFlag"
                    [canNotEditContainer]="selfAssignDisabledQuestions"
                    [disableCurrentContainer]="disableCurrentContainer"
                    [stationInstructions]="containerInformation.instructions"
                    [documentFields]="containerInformation.questions"
                    [isWidget]="isWidget"
                    [isArchitect]="isArchitect"
                    [containerRithmId]="containerInformation.containerRithmId"
                    [stationRithmId]="containerInformation.stationRithmId"
                    [displayGridTemplate]="true"
                    [inputFrameWidgetItems]="inputFrameWidgetItems"
                    [containerInformation]="containerInformation"
                    [options]="options"
                    [showWidgetFramesUpdate]="showWidgetFramesUpdate"
                    [headerFeatureFlag]="headerFeatureFlag"
                    [orderOfOperations]="orderOfOperations"
                    [showSelfAssign]="showSelfAssign"
                    [widgetColumnFeatureFlag]="widgetDataPhase2Flag"
                    [multiSortingFeatureFlag]="multiSortingFeatureFlag"
                    [containerAnswer]="!!containerAnswer.length"
                    [containerStationOverlayFeature]="
                      containerStationOverlayFeature
                    "
                    formControlName="inputFrameFieldForm"
                  />
                </div>
              }

              <!-- Show container template when is widget -->
              @if (isWidget) {
                <app-container-template
                  [relationshipWidgetFlag]="relationshipWidgetFlag"
                  [disableCurrentContainer]="disableCurrentContainer"
                  [canNotEditContainer]="selfAssignDisabledQuestions"
                  class="flex flex-col pb-14"
                  [ngClass]="{ 'pb-7': !isWidget }"
                  [stationInstructions]="containerInformation.instructions"
                  [documentFields]="containerInformation.questions"
                  [isWidget]="isWidget"
                  [isArchitect]="isArchitect"
                  formControlName="containerTemplateForm"
                  [containerRithmId]="containerInformation.containerRithmId"
                  [stationRithmId]="containerInformation.stationRithmId"
                  [showWidgetFramesUpdate]="showWidgetFramesUpdate"
                  [headerFeatureFlag]="headerFeatureFlag"
                  [orderOfOperations]="orderOfOperations"
                  [containerAnswer]="!!containerAnswer.length"
                  [showSelfAssign]="showSelfAssign"
                  [containerStationOverlayFeature]="
                    containerStationOverlayFeature
                  "
                  [widgetColumnFeatureFlag]="widgetDataPhase2Flag"
                  [multiSortingFeatureFlag]="multiSortingFeatureFlag"
                />
              }

              <!-- Footer -->
              <div
                class="w-full h-full max-width-footer hidden md:block"
                [ngClass]="{ relative: disableCurrentContainer }"
              >
                <ng-container *ngTemplateOutlet="toast" />

                <div [ngClass]="isWidget ? '' : 'w-[90%] fixed bottom-0'">
                  <div
                    class="bg-white flex space-x-4 px-5 py-2 footer"
                    [ngClass]="
                      isWidget ? 'w-full' : 'lg:col-start-3 lg:col-span-8'
                    "
                  >
                    <div class="flex-grow">
                      <span class="mr-3"
                        ><button
                          type="button"
                          mat-stroked-button
                          color="accent"
                          (click)="cancelContainer()"
                          data-testid="container-cancel"
                          id="container-cancel"
                          [disabled]="disableActions"
                        >
                          Cancel
                        </button></span
                      >
                      <button
                        mat-stroked-button
                        type="submit"
                        color="accent"
                        class="mx-2"
                        data-testid="container-save"
                        id="container-save"
                        [disabled]="
                          !containerForm.touched ||
                          (isWidget && !isUserAdminOrOwner) ||
                          disableActions ||
                          !containerAnswer.length ||
                          disableCurrentContainer
                        "
                      >
                        Save
                      </button>
                    </div>
                    <div
                      [matTooltip]="
                        !previousStations.length
                          ? 'There are no previous ' +
                            termsGeneric.Station.Plural +
                            ' to ' +
                            termsGeneric.Flow.Single
                          : ''
                      "
                    >
                      <!-- Show button previous flow -->
                      @if (allowPreviousButton) {
                        <button
                          type="button"
                          mat-raised-button
                          color="accent"
                          data-testid="container-previous-station"
                          id="container-previous-station"
                          (click)="confirmFlowContainerToPreviousStation()"
                          [disabled]="
                            disableActions ||
                            !previousStations.length ||
                            disableCurrentContainer
                          "
                        >
                          Previous {{ termsGeneric.Station.Single }}
                        </button>
                      }
                    </div>
                    <button
                      mat-raised-button
                      type="submit"
                      color="accent"
                      data-testid="container-flow"
                      id="container-flow"
                      [disabled]="
                        !containerForm.valid ||
                        (isWidget && !isUserAdminOrOwner) ||
                        disableActions ||
                        disableCurrentContainer
                      "
                      [ngClass]="{ 'animate-pulse': !flowButtonName }"
                      (click)="shouldFlowContainer = true"
                    >
                      {{ flowButtonName }}
                    </button>
                  </div>
                </div>
              </div>

              <!-- Footer mobile -->
              @if (!isWidget) {
                <div [ngClass]="{ relative: disableCurrentContainer }">
                  <mat-accordion
                    class="responsive-bottom-bar fixed bottom-0 left-0 w-full z-10 md:hidden"
                  >
                    <ng-container *ngTemplateOutlet="toast" />
                    <mat-expansion-panel
                      [(expanded)]="footerExpanded"
                      hideToggle
                    >
                      <mat-expansion-panel-header class="text-base">
                        <mat-panel-title data-testid="all-fields">
                          <i
                            class="fa-light"
                            [ngClass]="{
                              'fa-chevron-down': footerExpanded,
                              'fa-chevron-up': !footerExpanded
                            }"
                          ></i>
                        </mat-panel-title>
                        <mat-panel-description class="justify-end space-x-2">
                          @if (allowPreviousButton && previousStations.length) {
                            <button
                              type="button"
                              id="container-previous-station"
                              data-testid="container-previous-station"
                              (click)="confirmFlowContainerToPreviousStation()"
                              [disabled]="disableCurrentContainer"
                              mat-button
                              color="accent"
                            >
                              PREVIOUS
                            </button>
                          }

                          <button
                            type="submit"
                            mat-raised-button
                            id="container-flow"
                            data-testid="container-flow"
                            [disabled]="
                              !containerForm.valid ||
                              (isWidget && !isUserAdminOrOwner) ||
                              disableActions ||
                              disableCurrentContainer
                            "
                            (click)="shouldFlowContainer = true"
                            color="accent"
                          >
                            {{ flowButtonName }}
                          </button>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <ng-template matExpansionPanelContent>
                        <button
                          type="submit"
                          data-testid="container-save"
                          id="container-save"
                          [disabled]="
                            !containerForm.valid ||
                            (isWidget && !isUserAdminOrOwner) ||
                            disableActions ||
                            !containerAnswer.length ||
                            disableCurrentContainer
                          "
                          mat-stroked-button
                          color="accent"
                        >
                          SAVE
                        </button>
                        <button
                          type="button"
                          id="container-cancel"
                          data-testid="container-cancel"
                          mat-stroked-button
                          (click)="cancelContainer()"
                        >
                          CANCEL
                        </button>
                      </ng-template>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              }
            </form>
          }
        </div>
      </div>
    }
  </mat-drawer-content>
</mat-drawer-container>

<ng-template #toast>
  <!-- Toast processing -->
  @if (disableCurrentContainer) {
    <div class="toast-processing">
      <span>Processing... </span>
      <app-loading-indicator [whiteSpinner]="true" [inlineText]="' '" />
    </div>
  }
</ng-template>
